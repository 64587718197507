<!--
 * @Author: your name
 * @Date: 2021-01-26 20:21:04
 * @LastEditTime: 2021-02-01 17:50:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\understand\index.vue
-->
<template>
  <div>
    <h1>打印教程</h1>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      msg: "了解",
    };
  },
  methods:{
    ceshi(){
      
    }
  },
  mounted() {
    window.print();
    let c = "17562105692";
    let a = "a bcadEFghiJklmN1234eey_ 11p<a></a>";
    let b = /^1[3-9][0-9]{9}/g;
    let d = /\w/g;
    let e = /./g;
    let j = /[%]/g;
    var str = "abcd tes%t@runoob.com 1234";
    var patt1 = /\b[\w.%]+@[\w.-]+\.[a-zA-Z]{2,3}\b/g;
    let h = /b.*d/;
    
    
    
    
    
    
    
  },
};
</script>
<style scoped>
</style>
